.play-show {
	height 100vh

	.canvas {
		position fixed
		top 0
		left 0
		bottom 0
		right 0
		margin 0
		text-align center
		background-color black
		color $white
		cursor none

		&:after {
			content ''
			position absolute
			top 6vh
			left 0
			right 0
			height 2px
			background-color $white
			transform translateY(-100%)
		}

		&.color-black {
			canvas-color($black)
		}

		.title {
			font-size 3vh
			height 6vh
			line-height 6vh
			padding-left 250px
			padding-right 10px
			text-align right
			overflow hidden
			overflow-wrap break-word
			text-overflow ellipsis
			-webkit-line-clamp 1
			-webkit-box-orient vertical
			display -webkit-box
			
			@media screen and (max-width: 575px) {
				font-size 2vh
			}
		}

		.canvas_text {
			display table
			width 100%
			height 94vh
			box-sizing border-box
			font-size 100%
			padding 20px
			font-family SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important

			&:not(.white-space-pre) {
				white-space normal
			}

			.resize-box {
				display table-cell
				vertical-align middle
				overflow hidden
				font-size 0.1em
				padding 0
				margin 0
				border 0
				outline 0
				line-height 1.1em

				small {
					display inline
					font-size 0.6em
				}
			}

			span {
				font-family SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important
			}
		}

		.helper {
			opacity 0.5
			position fixed
			top 0
			left 10px
			font-size 16px
			line-height 6vh
			font-weight 300

			&.hide {
				opacity 0
			}
		}
	}
}