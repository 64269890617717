.sortable-song-list,
.sortable-verse-list,
.sortable-news-list {
	position relative
	margin-bottom 20px

	.draggable-song,
	.draggable-verse,
	.draggable-news {
		display flex
		align-items stretch
		justify-content flex-start
		cursor pointer
		font-family monospace

		.song-id,
		.song-title,
		.verse-title,
		.news-title {
			white-space pre-line
		}

		.song-id {
			position relative
			width 60px
			flex-shrink 0

			&:after {
				content ''
				position absolute
				right 10px
				top 0
				bottom 0
				width 1px
				background-color $black
			}
		}

		.icon-edit,
		.icon-delete {
			cursor pointer
			font-weight bold
			border none
			background-color transparent
			margin-left auto
			align-self flex-start
		}

		.icon-delete {
			color red
		}

		.icon-edit + .icon-delete {
			margin-left 5px
		}
	}

	.ui-state-highlight {
		height 1.5em
		line-height 1.2em
		background-color $secondary
	}
}
