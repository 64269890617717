// DISPLAY FLEX MIXIN
dflex($direction, $align, $justify) {
    display flex
    flex-direction $direction
    align-items $align
    justify-content $justify
}

canvas-color($color) {
    color $color

    &:after {
        background-color $color
    }

    .resize-box span {
        color $color !important
    }
}

dark-bg() {

    .form-control {
        background-color $dark-grey
        color $white

        &.range-box {

            label {
                color $white
            }

            &.focus {

                input {
                    background-color $dark-grey
                    color $white
                }

                input,
                span {
                    color $white
                }
            }
        }
    }

    .SumoSelect>.CaptionCont>span.changed {
        color $white
    }

    .SumoSelect.open>.optWrapper {
        background-color $dark-grey
        box-shadow 0 10px 30px rgba(255, 255, 255, 0.15)
    }

    .SumoSelect>.optWrapper>.options li.opt {
        background-color $dark-grey
    }

    .SumoSelect .select-all>label,
    .SumoSelect>.CaptionCont,
    .SumoSelect>.optWrapper>.options li.opt label {
        color $white
    }

    .SumoSelect>.optWrapper>.options li.opt.selected label {
        color $primary
    }
}