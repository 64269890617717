.users-dashboard {
	.table-users {
		th:nth-child(1) {
			width: 125px;
		}
	}

	.role_change {
		width: 100px;
		max-width: 100%;
	}

	.community-roles {
		padding: 5px 0 0;

		&:not(:last-child) {
			border-bottom: 1px solid #dee2e6;
			margin-bottom: 5px;
			padding-bottom: 10px;
		}

		.label--activate {
			margin-bottom: 10px;
		}
	}
}
