/**
 * Custom button styles
 */
input[type='submit'],
input[type='reset'],
input[type='button'],
.btn {
    background-color $primary
    border none
    border-radius 0
    box-shadow none
    color $white
    cursor pointer
    display inline-block
    font-family $montserrat
    font-size 18px
    font-weight normal
    height 50px
    line-height 50px
    padding 0 30px
    margin-bottom 20px
    width auto
    outline none
    position relative
    text-align center
    text-shadow none
    appearance none
    transition background-color .2s ease-out, border-color .2s ease-out, color .2s ease-out

    @media screen and (max-width: 991px) {
    	padding 0 20px
    }

    &:hover,
    &:focus,
    &:active {
        box-shadow none
    }

    &:hover {
        color $white
        background-color $primary-hover
    }
    
    &.smaller-on-mobile {

        @media screen and (max-width: 991px) {
            font-size 16px
        }
    }
}

input[type='submit'].btn-danger,
input[type='reset'].btn-danger,
input[type='button'].btn-danger,
.btn-danger {
    background-color $error-color
    color $white
    cursor pointer

    &:hover {
        background-color #ff0000cc
        color $white
    }
}


input[type='submit'].btn-primary,
input[type='reset'].btn-primary,
input[type='button'].btn-primary,
.btn-primary {
    color $white
    background-color $primary
    cursor pointer

    &:hover {
        color $white
        background-color $primary-hover
    }
}

input[type='submit'].btn-secondary,
input[type='reset'].btn-secondary,
input[type='button'].btn-secondary,
.btn-secondary {
    color $white
    background-color $secondary
    cursor pointer

    &:hover {
        color $white
        background-color $secondary-hover
    }
}

.btn-secondary-outline {
    color $secondary
    background-color transparent
    border 2px solid $secondary
    height 50px
    line-height 46px

    &:hover {
        color $secondary-hover
        background-color transparent
        border 2px solid $secondary-hover
    }
}

.btn-tip {
    line-height 24px
    height 24px
    font-size 15px
    margin-bottom 0
    cursor pointer
}

.btn-fluid {
    display block
    width 100%
    margin-bottom 0
    cursor pointer
}

input[type='submit'],
input[type='reset'],
input[type='button'] {
    overflow hidden
}

button {
    background: none;
    border: none;
    cursor: pointer;
}