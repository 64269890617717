* {
	outline 0 !important
}

body {
	font-family $montserrat
	font-size 16px
	font-weight 300
	color $black

	&.menu-open {

		@media screen and (max-width: 991px) {
			overflow hidden
		}
	}
}

span {
	font-family $montserrat
	display inline-block
}

.alert {
	margin-bottom 25px

	&.alert-danger {
		background-color $error-color
		color $black
		border none
		border-radius 0
	}

	&.alert-success {
		background-color $secondary-hover
		color $black
		border none
		border-radius 0
	}
}

.header-fix {
	padding-top 76px
	background-color transparent

	@media screen and (max-width: 991px) {
		padding-top 70px
	}

	&.alert-on {

		@media screen and (min-width: 1200px) {
			padding-top 120px
		}
	}

	&.main {
		padding-top 0 !important
	}
}

.section {
	padding-top 100px
	padding-bottom 100px

	@media screen and (max-width: 991px) {
		padding-top 50px
		padding-bottom 50px
	}
}

.header-fix + .section {
	padding-top 70px

	@media screen and (max-width: 991px) {
		padding-top 50px
	}
}

.section-title {
	margin-bottom 50px
	text-align center

	@media screen and (max-width: 991px) {
		margin-bottom 30px
	}
}

.main-title {
	margin-bottom 20px
}

.border-primary {
	border 1px solid $primary
}

.border-secondary {
	border 1px solid $secondary
}

.border-grey {
	border 1px solid $input-default-border
}

.btn-flex-wrapper {
	display flex
	align-items flex-start
	justify-content center
	flex-wrap wrap

	@media screen and (max-width: 991px) {
		flex-wrap wrap
		flex-direction column-reverse
		align-items center
	}
}

.color-primary {
	color $primary
}

.color-secondary {
	color $secondary
}

.color-white {
	color $white
}

.color-grey {
	color $grey
}

.w-fit-content {
	width: fit-content
}