.pagination {
	justify-content center
	margin-bottom 3.125em

	.page-item {

		&.active {

			.page-link {
				background-color $primary
				border-color $primary
				color $white
			}
		}

		&.disabled:first-child,
		&.disabled:last-child {
			display none
		}

		&.disabled .page-link {
			border-top none
			border-bottom none
		}

		&:first-child {
			margin-right 0.9375em
		}

		&:last-child {
			margin-left 0.9375em
		}

		.page-link {
			color $primary
			border-radius 0 !important
			transition all 0.3s ease-out

			&:focus {
				box-shadow none
			}

			&:hover {
				background-color $primary
				border-color $primary
				color $white
			}
		}
	}
}