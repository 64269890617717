label {
	font-family $montserrat
	font-weight bold
	font-size 14px
	line-height 18px
	display flex
	flex-wrap wrap
}

.error-response {
	display none
	color $error-color
	font-family $montserrat
	font-weight 300
	font-size 14px
	line-height 24px
	margin-top 3px
}


i.public {
	display inline-block
	position relative
	width 18px
	height 18px
	background-image url('../img/svg/public.svg')
	background-size contain
	background-position center
	background-repeat no-repeat
	margin-right 4px
}

i.private {
	display inline-block
	position relative
	width 18px
	height 18px
	background-image url('../img/svg/private.svg')
	background-size contain
	background-position center
	background-repeat no-repeat
	margin-right 4px
}

span.required {
	font-size 19px
	color $primary
	line-height 18px
	position relative
	height 18px
	margin-left 4px
	margin-right 4px
}

.form-row {

	[class*=col-]:not(:first-child) {
		padding-left 15px
	}

	[class*=col-]:not(:last-child) {
		padding-right 15px
	}

	[class*=col-xl-] {

		@media screen and (max-width: 1199px) {
			padding-left 5px !important
			padding-right 5px !important
		}
	}

	[class*=col-lg-] {

		@media screen and (max-width: 991px) {
			padding-left 5px !important
			padding-right 5px !important
		}
	}

	[class*=col-md-] {

		@media screen and (max-width: 768px) {
			padding-left 5px !important
			padding-right 5px !important
		}
	}

	[class*=col-sm-] {

		@media screen and (max-width: 575px) {
			padding-left 5px !important
			padding-right 5px !important
		}
	}
}

.form-control {
	border none
	border-bottom 1px solid $input-default-border
	border-radius 0
	padding 7px 0
	height 34px
	line-height 20px
	font-family $montserrat
	font-weight 300
	font-size 16px

	&:hover,
	&:focus {
		box-shadow none
		border-color $primary
	}

	&::placeholder {
		color $grey
	}

	&::-webkit-input-placeholder {
		color $grey
	}

	&::-moz-placeholder {
		color $grey
	}

	&:-ms-input-placeholder {
		color $grey
	}

	&:-moz-placeholder {
		color $grey
	}

	&[readonly] {
		background-color $white
		cursor default
		pointer-events none
	}

	&.range-box {
		display flex
		cursor text
		position relative

		label {
			font-weight 300
			color $black
			cursor text
			font-size 16px
			line-height 20px
		}

		input,
		span:not(.clear-button) {
			display none
		}

		&.focus {
			border none
			padding 0
			height auto

			label {
				display none
			}

			input,
			span:not(.clear-button) {
				display inline-block
				color $black
				flex 1
			}

			.clear-button {
				display none
			}

			input {
				width 100%
				border none
				border-bottom 1px solid $primary
				border-radius 0
				padding 7px 0
				height 34px
				font-family $montserrat
				font-weight 300
				font-size 16px
				line-height 20px
			}

			span:not(.clear-button) {
				font-weight 300
				font-size 16px
				white-space nowrap
				line-height 30px
				padding 0 5px
			}
		}
	}
}

textarea.form-control{
	overflow hidden
	min-height 94px
}

.input-wrapper {
	display block
	position relative
}

.clear-button {
	position absolute
	top 0
	right 0
	bottom 0
	background-image url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACiSURBVEhL7ZBBCoRADATnfX5pdf+9eNC02jAH0TGduActaBAhVWJ5eSwfW7c+SsABVxODbbL9bEoct3DABecp9YE37nYoceV2wSOQo+SKKCxKWoThUXIkTouSvUB6lNShcVt6lCDAID8gPQr+Ekbg9l9dRxnaexfKUSAt3iIOj18RhsU9IjmuCNy3SpS4HF+bEiV1HM4mepsSJXDA9fI4SpkB/eOGpW3gIaEAAAAASUVORK5CYII=")
	cursor pointer
	width 15px
	background-position center
	background-size contain
	background-repeat no-repeat
	z-index 1
}

/*
 * AUTOCOMPLETE BACKGROUND AND COLOR CHANGE
 */
@-webkit-keyframes autofill {

	to {
		color $black
		background $white
	}
}

input:-webkit-autofill {
	-webkit-animation-name autofill
	-webkit-animation-fill-mode both
}

.js-custom-select {
	padding: 0;
	border: none;
	background: none;
	border-radius: 0;
	line-height: 0;
	width: 100%;
}

.form-group {
	margin-bottom 30px
	position relative

	&.error {

		label {
			color $error-color

			&.form-check-label {
				color $error-color
			}

			span.required {
				color $error-color
			}
		}

		.form-control {
			color $error-light-color
			border-color $error-light-color

			&::placeholder {
				color $error-light-color
			}

			&::-webkit-input-placeholder {
				color $error-light-color
			}

			&::-moz-placeholder {
				color $error-light-color
			}

			&:-ms-input-placeholder {
				color $error-light-color
			}

			&:-moz-placeholder {
				color $error-light-color
			}
		}

		.SumoSelect, .custom-select {
			border-bottom 1px solid $error-response-color
		}

		.SumoSelect > .CaptionCont > span {
			color #faa
		}

		.SumoSelect > .CaptionCont > label > i {
			background-image url('../img/svg/select-arrow-error.svg')
		}

		.error-response {
			display block
		}
	}

	&.form-check {
		padding-left 0
		margin-bottom 0
	}
	
	&.form-radio {
		margin-bottom 20px
	}

	&.has-clear {
		margin-right 20px

		.clear-button {
			right -20px
		}
	}
}

/*
 * HIDE NUMBER INPUT SPINNERS
 */
input[type=number] {
	// Firefox:
	-moz-appearance textfield

	// Webkit:
	&::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
		-webkit-appearance none
		margin 0
	}
}

input[readonly],
input[disabled] {
	pointer-events: none;
	user-select: none;
}

/**
 * Custom Radio button
 */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
	position absolute
	left -9999px
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
	position relative
	padding-left 24px
	cursor pointer
	line-height 24px
	display block
	font-family $montserrat
	font-weight bold
	font-size 14px
	color $black
	margin-top 5px
	margin-bottom 5px
	transition all .2s
}

[type="radio"]:checked + label {
	color $black
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
	content ''
	position absolute
	left 0
	top 0
	bottom 0
	width 14px
	height 14px
	margin auto
	border 1px solid $input-default-border
	border-radius 100%
	background #fff
	transition all .2s
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
	content ''
	position absolute
	top 0
	left 0
	bottom 0
	width 14px
	height 14px
	margin auto
	background $primary
	border-radius 100%
	transition all 0.2s
}

[type="radio"]:not(:checked) + label:after {
	opacity 0
	transform scale(0)
}

[type="radio"]:checked + label:after {
	opacity 1
	transform scale(1)
}

[type="radio"] + label:hover {
	color $primary !important
}

[type="radio"] + label:hover:before {
	border 1px solid $primary !important
}
/**
 * END Custom radio button
 */

/**
 * Custom checkbox
 */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
	position absolute
	left -9999px
}

[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
	position relative
	padding-left 24px
	cursor pointer
	line-height 20px
	display block
	color $black
	font-weight bold
	margin-top 0
	margin-bottom 20px
	transition all .2s
}

[type="checkbox"]:not(:checked) + label.label-grey,
[type="checkbox"]:checked + label.label-grey {
	margin-top 10px
	font-weight 300
	color $grey
}

[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
	content ''
	position absolute
	left 0
	top 0
	bottom 0
	width 14px
	height 14px
	margin auto
	border 1px solid $input-default-border
	background $white
	border-radius 0
	box-shadow none
	transition all .2s
}

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
	content ''
	position absolute
	top 0
	left 0
	bottom 0
	width 14px
	height 14px
	margin auto
	background-color $primary
	background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAGCAYAAAD+Bd/7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAABMSURBVAiZfc0xDkAAFIPhd2Kr1WRjcAExuIgzGUTIZ/AkImjSofnbNBAfHvzAHjOKNzhiQ42IDFXCDivaaxAJd0xYshT3QqBxqnxeHvhunpu23xnmAAAAAElFTkSuQmCC')
	background-position center center
	background-repeat no-repeat
	transition all .2s
}

[type="checkbox"]:not(:checked) + label:after {
	opacity 0
	transform scale(0)
}

[type="checkbox"]:checked + label:after {
	opacity 1
	transform scale(1)
}

[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
	box-shadow none
	border-color lightgray
	background-color $grey
}

[type="checkbox"]:disabled:checked + label:after {
	color lightgray
}

[type="checkbox"]:disabled + label {
	color lightgray
}

[type="checkbox"] + label:hover {
	color $primary !important

	span.required {
		color $primary !important
	}
}

[type="checkbox"] + label:hover:before {
	border 1px solid $primary !important
}
/**
 * END Custom checkbox
 */

/*
 * Switch button
 */
.switch {
	position relative
	display inline-block
	width 65px
	height 40px
}

.switch input {
	opacity 0
	width 0
	height 0
}

.slider {
	position absolute
	top 0
	left 0
	bottom 0
	right 0
	cursor pointer
	background-color $grey
	border-radius 40px
	transition all .4s linear
}

.slider:before {
	content ''
	display block
	position absolute
	height 40px
	width 40px
	left 0
	bottom 0
	background-color $white
	border 1px solid $grey
	border-radius 20px
	transition all .4s
}

.slider:after {
	content ''
	position absolute
	top 10px
	left 10px
	width 20px
	height 20px
	background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAB8SURBVDhPYxgFgwB0nbVk6D+vAOVRCLpOWzP0nP3E0H32EkP9fyaoKJkAZhgIg9gUgWFkWM/ZcJwKSTas/7wAQ8+Zd8AY+4ihgWTDYACkGGQgsqFkGwYDMEMhhuRSZhgMIFz1n3LDYABs6Jmn1DEMBnqPcUJZowAXYGAAALXlceSZ91SIAAAAAElFTkSuQmCC')
	background-size contain
	background-position center
	background-repeat no-repeat
	transition .4s
	opacity 0
}

input:checked + .slider {
	background-color $primary
}

input:focus + .slider {
	box-shadow none
}

input:checked + .slider:before,
input:checked + .slider:after {
	transform translateX(25px)
	opacity 1
}

input:checked + .slider:before {
	border-color $primary
}