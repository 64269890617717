.table {
	tr {
		td,
		th {

			&[data-filter] {
				position relative
				cursor pointer
				padding-right 1.25em
				vertical-align middle

				&:before {
					position absolute
					right 0.3125em
					top 50%
					transform translateY(calc(-50% - 0.3125em))
					color lightgrey
					content "\f106"
					font-family "Font Awesome 5 Pro"
					font-weight 900
				}

				&:after {
					position absolute
					right 0.3125em
					top 50%
					transform translateY(calc(-50% + 0.3125em))
					color lightgrey
					content "\f107"
					font-family "Font Awesome 5 Pro"
					font-weight 900
				}

				&.asc {

					&:after {
						color black
					}
				}

				&.desc {

					&:before {
						color black
					}
				}
			}
		}
	}
}