.index {
	.nav-link {
		h2 {
			font-size: 42px;
		}
	}
}

.table--changelog {
	margin-bottom: 0;

	td:first-child,
	th:first-child {
		width: 130px;
	}

	td:nth-child(2),
	th:nth-child(2) {
		width: 80px;
	}
}
