.modal {

	.modal-dialog {
		box-shadow: 1px 0 7px 3px $grey;

		.modal-content {
			border-radius 0
			border none

			.modal-header {
				padding 15px 0
				border none
				border-radius 0

				.modal-title {
					display flex
					flex-wrap wrap
					padding-right 50px
				}

				.btn-danger {
					font-size 16px
					font-weight 300
				}

				svg {
					margin-right 10px
				}

				.close {
					transform translateX(-100%)

					svg {
						margin-right 0
						stroke $primary
						transition all .3s
					}

					&:hover {

						svg {
							stroke $primary-hover
						}
					}
				}
			}

			.modal-body {
				padding 15px

				.bootbox-body {
					font-size 24px
					font-weight 700
				}
			}

			.modal-footer {
				margin-left 5px
				margin-right 5px
				border none
			}
		}
	}

	&.tipp {

		.modal-content {

			.modal-header {
				background-color $dark-grey
				color $white
			}
		}
	}
}