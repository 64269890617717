header {
	.navbar {
		background-color $dark-grey
		padding 15px 0
		transition background-color .3s ease-out

		&.main {
			background-color transparent
			transition background-color .3s ease-out
		}

		&.scrolled,
		&.menu-open {
			background-color $dark-grey
			transition background-color .3s ease-out
		}

		.navbar-brand {
			padding 0
			margin 0
			color $white
			transition .5s ease

			@media screen and (max-width: 575px) {
				margin-right 15px
			}

			&:hover {
				color $secondary
			}

			img {
				width 128px
				height 32px
			}
		}

		.hamburger {
			display none
			padding 0 0 0 15px
			line-height 0.1px

			@media screen and (max-width: 991px) {
				display inline-block
			}

			.hamburger-box {
				height 40px
			}
			
			.hamburger-inner {
				top 11px
			}

			.hamburger-inner,
			.hamburger-inner:after,
			.hamburger-inner:before {
				background-color $secondary
				height 2px
			}

			&.is-active .hamburger-inner {
				background-color transparent
			}

			&:hover {
				opacity 1
			}
		}

		.menu-wrapper {
			display flex
			flex-basis auto
			flex-grow 1
			align-items center

			@media screen and (max-width: 991px) {
				display block
				position fixed
				top 64px
				left 0
				right 0
				bottom 0
				background $dark-grey
				transform translateY(100%)
				transition transform .3s ease-out
				padding-top 20px
				overflow auto
				text-align center

				&.is-active {
					transform translateY(0)
				}
			}
		}

		.navbar-nav {

			&.navbar-side {

				@media screen and (min-width: 992px) {
					transform translateX(17.5px)
				}
			}

			.nav-item {

				.nav-link {
					font-family $montserrat
					font-weight 300
					font-size 16px
					color $white
					padding 10px 17.5px
					transition color .5s ease

					&:hover {
						color $secondary
					}

					&.active {
						color $secondary
					}

					@media screen and (max-width: 991px) {
						padding 0 15px 15px
					}
				}
			}
		}

		.profile-item-wrapper {
			padding 8px 0 8px 20px
			cursor pointer

			@media screen and (max-width: 991px) {
				display none
			}
		}

		.profile-item {
			background-color $secondary
			border-radius 50%
			color $black
			font-family $montserrat
			font-weight 300
			font-size 16px
			line-height 30px
			position relative
			width 30px
			height 30px
			text-align center
			padding 0
			margin 0
			display block

			@media screen and (max-width: 991px) {
				line-height 40px
				width 40px
				height 40px
				margin-left auto
				margin-right auto
			}
		}

		.dropdown-menu {
			margin-top 14px
			border none
			background-color $dark-grey
			border-radius 0
			padding 0 0 4px
			box-shadow $default-box-shadow
			transform translateX(15px)
			transition opacity 0.3s ease-out

			@media screen and (max-width: 991px) {
				box-shadow none
				position relative
				top 0
				left 0
				transform none
				text-align center
				float none
				opacity 1
				pointer-events all
			}

			.dropdown-item {
				font-family $montserrat
				font-weight 300
				font-size 16px
				color $white
				transition color .5s ease
				position relative
				padding 6px 25px

				@media screen and (max-width: 991px) {
					padding 0 15px 15px
				}

				&:hover,
				&:focus,
				&:active,
				&.active {
					background-color transparent
					color $secondary
				}

				&:not(:last-child):after {
					content ''
					position absolute
					bottom 0
					left 20px
					right 20px
					height 1px
					background-color $white
					opacity 0.5

					@media screen and (max-width: 991px) {
						display none
					}
				}
			}
		}

		.community_change_form {
			width: 100%;
			margin-left: 20px;

			@media screen and (max-width: 991px) {
				margin: 0 auto 30px;
				max-width 50%;
			}
		}
	}
}
